/* overwrites values in player-component css file */

.rhap_container {
  width: 212px;
  background-color: transparent;
}

.rhap_container:focus {
  outline: 0;
}

.rhap_volume-bar-area:focus {
  outline: 0;
}

.rhap_stacked .rhap_controls-section {
  margin: 0px 12px 0 0;
}

.rhap_play-pause-button {
  outline: 0;
}

.rhap_volume-button {
  outline: 0;
}

.rhap_play-pause-button:hover {
  opacity: 0.8;
}
